import { createGetKcContext } from "keycloakify/login";

export type KcContextExtension =
	| { pageId: "login.ftl";  };

//NOTE: In most of the cases you do not need to overload the KcContext, you can
// just call createGetKcContext(...) without type arguments.
// You want to overload the KcContext only if:
// - You have custom plugins that add some values to the context (like https://github.com/micedre/keycloak-mail-whitelisting that adds authorizedMailDomains)
// - You want to add support for extra pages that are not yey featured by default, see: https://docs.keycloakify.dev/contributing#adding-support-for-a-new-page
export const { getKcContext } = createGetKcContext<KcContextExtension>({
	mockData: [
		{
			pageId: "login.ftl",
			locale: {
				//When we test the login page we do it in english
				currentLanguageTag: "en",
			},
			//Uncomment the following line for hiding the Alert message
			//"message": undefined
			//Uncomment the following line for showing an Error message
			//message: { type: "error", summary: "This is an error" }
		},
	],
	// Defined in vite.config.ts
	// See: https://docs.keycloakify.dev/environnement-variables
	mockProperties: {
		MY_ENV_VARIABLE: "Mocked value"
	}
});

export const { kcContext } = getKcContext({
	// Uncomment to test the login page for development.
	//mockPageId: "login.ftl",
});


export type KcContext = NonNullable<ReturnType<typeof getKcContext>["kcContext"]>;
