import { createGetKcContext } from "keycloakify/account";

export const { getKcContext } = createGetKcContext({
	mockData: [],
	mockProperties: {
		MY_ENV_VARIABLE: "Mocked value"
	}
});

export const { kcContext } = getKcContext({
	//mockPageId: "password.ftl",
});

export type KcContext = NonNullable<ReturnType<typeof getKcContext>["kcContext"]>;
